<template>
  <div class="wrapper">
    <transition name="scale" mode="out-in">
         <div v-if="step==1">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-repas-sejour')"/>
            <MagicInput :cval="dejeuner" modelValue="dejeuner" :min="0" :unit="$t('default.fois')" :name="$t('default.petit-dejeuner')" src="f&b-breakfast.svg" :step="1"/>
            <MagicInput :cval="restaurant" modelValue="restaurant" :min="0" :unit="$t('default.fois')" :name="$t('default.restaurant')" src="f&b-resto.svg" :step="1"/>
            <MagicInput :cval="piquenique" modelValue="piquenique" :min="0" :unit="$t('default.fois')" :name="$t('default.pique-nique')" src="f&b-picnic.svg" :step="1"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
        <div v-else-if="step==2">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-repas-sejour')"/>
            <MagicInput :cval="robinet" modelValue="robinet" :min="0" :unit="$t('default.litres-jour')" :name="$t('default.eau-robinet')" src="f&b-watertap.svg" :step="0.1"/>
            <MagicInput :cval="bouteille" modelValue="bouteille" :min="0" :unit="$t('default.litres-jour')" :name="$t('default.eau-bouteille')" src="f&b-waterbottle.svg" :step="0.1"/>
            <MagicInput :cval="cafe" modelValue="cafe" :min="0" :unit="$t('default.tasses-jour')" :name="$t('default.cafe')" src="f&b-coffee.svg" :step="1"/>
            <MagicInput :cval="soda" modelValue="soda" :min="0" :unit="$t('default.litres-jour')" :name="$t('default.soda')" src="f&b-soda.svg" :step="0.1"/>
            <MagicInput :cval="jusfruit" modelValue="jusfruit" :min="0" :unit="$t('default.litres-jour')" :name="$t('default.jus-fruit')" src="f&b-juice.svg" :step="0.1"/>
            <MagicInput :cval="alcool" modelValue="alcool" :min="0" :unit="$t('default.litres-jour')" :name="$t('default.alcool')" src="f&b-alcohol.svg" :step="0.1"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="previousStep()">Précédent</button> 
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>

<style lang="less">
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .hello {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .hello h2 {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    .input-ctn {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import MagicInput from '@/components/MagicInput.vue'

  import store from '../store';

  export default {
    name: 'repas',
    data: function() {
      return {
        nbStep: 2,
      }
    },
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', false);
      this.$nextTick(function () {
        this.setAdvancement();
      })
    },
    methods: {
      nextStep: function () {
        var cStep = this.step;
        if(this.step < this.nbStep) {
          this.$router.replace({ name: 'repas', query: { step: parseInt(cStep)+1 }})
          this.setAdvancement();
        }else {
          this.setAdvancement(this.nbStep+1)
          this.$router.replace({ name: 'activites', query: { step: 1 }})
        }
      },
      previousStep: function () {
        var cStep = this.step;
        if(this.step > 0) {
          this.$router.replace({ name: 'repas', query: { step: parseInt(cStep)-1 }})
          this.setAdvancement();
        }
      },
      setAdvancement: function(step) {
        var cStep = step || this.step;
        var advancement = this.advancement;
        advancement.repas = cStep * 100 / this.nbStep;
        this.$store.commit('advancement', advancement);
      }
    },
    watch: {
      $route(to, from) {
        console.log(from, to);
        if(to.name == 'repas'){
          this.setAdvancement(to.query.step);
        }
      }
    },
    components: {
      HelloWorld,
      MagicInput
    },
    computed: {
      dejeuner: () => store.state.dejeuner,
      restaurant: () => store.state.restaurant,
      piquenique: () => store.state.piquenique,

      robinet: () => store.state.robinet,
      bouteille: () => store.state.bouteille,
      cafe: () => store.state.cafe,
      soda: () => store.state.soda,
      jusfruit: () => store.state.jusfruit,
      alcool: () => store.state.alcool,

      advancement: () => store.state.advancement,
      step() {
        // We will see what `params` is shortly
        return this.$route.query.step
      }
    },
  }
  </script>
