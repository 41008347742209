<template>
  <div class="wrapper">
    <transition name="scale" mode="out-in">
         <div v-if="step==1">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-logement')"/>
            <MagicInput :cval="hotel1" modelValue="hotel1" :min="0" :unit="$t('default.nuits')" :name="$t('default.hotel1-2')" src="logement-1star.svg" :step="1"/>
            <MagicInput :cval="hotel4" modelValue="hotel4" :min="0" :unit="$t('default.nuits')" :name="$t('default.hotel3-4')" src="logement-3stars.svg" :step="1"/>
            <MagicInput :cval="auberge" modelValue="auberge" :min="0" :unit="$t('default.nuits')" :name="$t('default.auberge')" src="logement-auberge.svg" :step="1"/>
            <MagicInput :cval="camping" modelValue="camping" :min="0" :unit="$t('default.nuits')" :name="$t('default.camping')" src="logement-camping.svg" :step="1"/>
            <MagicInput :cval="bnb" modelValue="bnb" :min="0" :unit="$t('default.nuits')" :name="$t('default.bnb')" src="logement-b&b.svg" :step="1"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>

<style lang="less">
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .hello {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .hello h2 {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    .input-ctn {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import MagicInput from '@/components/MagicInput.vue'

  import store from '../store';

  export default {
    name: 'logement',
    data: function() {
      return {
        nbStep: 1,
      }
    },
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', false);
      this.$nextTick(function () {
        this.setAdvancement();
      })
    },
    methods: {
      nextStep: function () {
        var cStep = this.step;
        if(this.step < this.nbStep) {
          this.$router.replace({ name: 'logement', query: { step: parseInt(cStep)+1 }})
          this.setAdvancement();
        }else {
          this.setAdvancement(this.nbStep+1)
          this.$router.replace({ name: 'bilan'})
        }
      },
      previousStep: function () {
        var cStep = this.step;
        if(this.step > 0) {
          this.$router.replace({ name: 'logement', query: { step: parseInt(cStep)-1 }})
          this.setAdvancement();
        }
      },
      setAdvancement: function(step) {
        var cStep = step || this.step;
        var advancement = this.advancement;
        advancement.logement = cStep * 100 / this.nbStep;
        this.$store.commit('advancement', advancement);
      }
    },
    watch: {
      $route(to, from) {
        console.log(from, to);
        if(to.name == 'logement'){
          this.setAdvancement(to.query.step);
        }
      }
    },
    components: {
      HelloWorld,
      MagicInput
    },
    computed: {
      hotel1: () => store.state.hotel1,
      hotel4: () => store.state.hotel4,
      auberge: () => store.state.auberge,
      camping: () => store.state.camping,
      bnb: () => store.state.bnb,

      advancement: () => store.state.advancement,
      step() {
        // We will see what `params` is shortly
        return this.$route.query.step
      }
    },
  }
  </script>
