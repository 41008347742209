<template>
  <div class="wrapper">
    <div class="row">
      <div class="column">
        <SimpleInput :cval="nbPeople" modelValue="nbPeople" :min="1" :unit="$t('default.personnes')" :name="$t('default.nombre')" :required="1" :step="1"/>
        <SimpleInput :cval="duration" modelValue="duration" :min="1" :unit="$t('default.jours')" :name="$t('default.duree')" :required="1" :step="1"/>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col controls">
        <button @click="nextStep()">Suivant</button> 
      </div>
    </div>
  </div>
</template>

<style lang="less">
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .column {
    display: flex;
    flex-direction: column;
  }
</style>

<script>
  // @ is an alias to /src
  import SimpleInput from '@/components/SimpleInput.vue'
  import store from '../store';

  export default {
    name: 'infos',
    data: function() {
      return {
        nbStep: 1,
      }
    },
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', false);
      this.$nextTick(function () {
        this.setAdvancement();
      })
    },
    methods: {
      nextStep: function () {
        var cStep = this.step;
        if(this.step < this.nbStep) {
          this.$router.replace({ name: 'infos', query: { step: parseInt(cStep)+1 }})
          this.setAdvancement();
        }else {
          this.setAdvancement(this.nbStep+1)
          this.$router.replace({ name: 'transports', query: { step: 1 }})
        }
      },
      previousStep: function () {
        var cStep = this.step;
        if(this.step > 0) {
          this.$router.replace({ name: 'infos', query: { step: parseInt(cStep)-1 }})
          this.setAdvancement();
        }
      },
      setAdvancement: function(step) {
        var cStep = step || this.step;
        var advancement = this.advancement;
        advancement.infos = cStep * 100 / this.nbStep;
        this.$store.commit('advancement', advancement);
      }
    },
    watch: {
      $route(to, from) {
        console.log(from, to);
        if(to.name == 'infos'){
          this.setAdvancement(to.query.step);
        }
      }
    },
    components: {
      SimpleInput
    },

    computed: {
      nbPeople: () => store.state.nbPeople,
      duration: () => store.state.duration,
      advancement: () => store.state.advancement,
      step() {
        // We will see what `params` is shortly
        return this.$route.query.step
      }
    },
  }
  </script>
