<template>
    <div :class="'simpleInput col-md-3 col-12 ' + isSet()">
        <div class="wrapper">
            <div class="img-wrapper" v-if="src">
                <img :src="src">
            </div>
            <h2>{{ name }}<span v-if="required" class="required">{{ $t('default.obligatoire')}}</span></h2>
            <div class="input">
                <input :class="isSet()" type="number" :value="getCval()" @input="handleInput" :min="min" :step="step"/>
                <!--<input v-bind="$attrs" type="number" :min="min" :max="max" :step="step">-->
                <div class="unit">{{unit}}</div>
                <button class="less" @click="less">-</button>
                <button class="more" @click="more">+</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SimpleInput',
    props: {
        modelValue: {
            type: String,
            default: '',
            required: true
        },
        cval: {
            type: Number,
            default: 0,
            required: true
        },
        name: {
            type: String,
            default: '',
            required: true
        },
        unit: {
            type: String,
            default: '',
            required: true
        },
        min: {
            type: Number,
            default: 0,
            required: true
        },
        max: {
            type: Number,
            default: 1000,
            required: false
        },
        src: {
            type: String,
            default: '',
            required: true
        },
        step: {
            type: Number,
            default:1,
            required: true
        },
        required: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        less() {
            if(this.getCval() <= this.min) return;
            this.$store.commit(this.modelValue, this.getCval()-this.step)
        },
        more() {
            this.$store.commit(this.modelValue, this.getCval()+this.step)
        },
        getCval() {
            return this.toFixedNumber(this.cval, 2);
        },
        isSet() {
            if(this.getCval() > this.min || this.min > 0) {
                return 'defined';
            }
            return '';
        },
        handleInput (e) {
            if(Number.parseFloat(e.target.value) < this.min) {
                this.$store.commit(this.modelValue, this.min);
                return;
            }
            this.$store.commit(this.modelValue, e.target.value)
        },
        toFixedNumber(num, digits, base){
          var pow = Math.pow(base||10, digits);
          return Math.round(num*pow) / pow;
        }
    }
}
</script>

<style lang="less">
    @borderColor: rgba(92, 127, 190, 0.2);
    .column .simpleInput h2 {
        width: 100vw;
        text-align: left;
        .required {
            border-radius: 3px;
            background-color: white;
            font-size: 12px;
            color: #5C7FBE;
            padding: 3px;
            margin-left: 10px;
        }
    }
    .simpleInput {
        transition: all linear .25s;
        transform: translateY(0px);
        .wrapper {
            position: relative;
            padding-bottom: 0px;
            .img-wrapper {
                height: 110px;
                min-width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                object-fit: contain;
                width: 45%;
            }
            h2 {
                font-weight: 300;
                font-size: 14px;
                color: #20194C;
            }
            *:focus {
                outline: none;
            }
            button.less {
                border-left: @borderColor solid 1px !important;
                border-right: @borderColor solid 1px !important;
            }
            .unit {
                padding: 0 4px;
                font-size: 13px;
            }
            .input {
                background-color: white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                height: 34px;
                border: rgba(92, 127, 190, 0.2) solid 1px;
                margin: 9px;
                border-radius: 4px;
                /* Remove native arrows on number input */

                /* Chrome, Safari, Edge, Opera */
                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
                }

                /* Firefox */
                input[type=number] {
                  -moz-appearance: textfield;
                  background-color: transparent;
                  text-indent: 3px;
                }

                button {
                    margin-top: 0;
                    min-width: 20px;
                    height: 30px;
                    background-color: transparent;
                    border: none;
                    padding: 0 3px;
                    box-sizing:  content-box;
                    color: #2c3e50;
                    margin-left: 0;
                }

                input {
                    border: none;
                    height: 100%;
                    width: 100%;
                    unit {

                    }
                }
            }
        }
    }
    @media screen and (max-width: 762px) {
        .simpleInput {
            .wrapper {
                .input {
                    margin: 0;
                }
            }
        }
    }
</style>