<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  //import Chart from '@/components/Chart.vue'

  import store from '../store';

  export default {
    name: 'bilan',
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', true);
    },
    methods: {
      gotoCompensation: function(){
        var lang = this.$i18n.locale;
        if(lang == 'fr') {
          window.open('https://www.carbonfri.ch/participationco2-2?co2='+this.cTotal, '_blank');  
        }else{
          window.open('https://www.carbonfri.ch/de-participationco2-2?co2='+this.cTotal, '_blank');
        }
      },
      getFormLink: function(){
        var lang = this.$i18n.locale;
        if(lang == 'fr') {
          return 'https://www.carbonfri.ch/participationco2-2?co2='+this.cTotal;
        }else{
          return 'https://www.carbonfri.ch/de-participationco2-2?co2='+this.cTotal;
        }
      },
      serializeDatas: function(){
        var datas = {}
        datas.duree = this.duration;
        datas.personnes = this.nbPeople;
        datas.transports = this.cTotalTransport;
        datas.repas = this.cTotalRepas;
        datas.activites = this.cTotalActivites;
        datas.logement = this.cTotalLogement;
        datas =  JSON.stringify(datas);
        return datas;
        //return datas;
      },
      // grammes => tonnes
      g2t: function(grammes){
        return grammes / 1000000;
      },
      t2g: function(t){
        return parseFloat(t) * 1000000;
      },
      round: function(num) {
        // 1000 = 3 decimals, 100 = 2 decimals, 10 = 1 decimal, ...
        return Math.round((num + Number.EPSILON) * 100) / 100;
      },
      generateGraph: function(){
        return {
          height: 220,
          //currentTotal: 
        }
      },
    },
    data: function() {
      return {
        moyenneSuisse: 0.03,//5.2 / 365,
        collapse1: true,
        collapse2: false,
        collapse3: false,
        collapse4: false,
      }
    },
    components: {
      HelloWorld,
    },
    computed: {
      nbPeople: () => store.state.nbPeople,
      duration: () => store.state.duration,
      cTotal: function(){
        var t = this.cPlane + this.cCar + this.cTrain + this.cBus + this.cBike + this.cCablecar;
        t += this.cRestaurant + this.cDejeuner + this.cPiquenique;
        t += this.cRobinet + this.cBouteille + this.cCafe + this.cSoda + this.cJusfruit + this.cAlcool;
        t += this.cSki + this.cRando + this.cBaignade + this.cMusee;
        t += this.cHotel1 + this.cHotel4 + this.cAuberge + this.cBnb + this.cCamping;
        return this.round(t);
      },
      cPerPeople: function(){
        var n = this.cTotal;
        var nbPeople = this.nbPeople;
        return this.round(n/nbPeople);
      },
      globalMoyenneSuisse: function(){
        return parseFloat(this.cPerPeople / this.duration) * 100 / this.moyenneSuisse;
      },
      cPerDay: function(){
        var n = this.cTotal;
        return this.round(n/this.duration);
      },
      // Calculated values 
      cPlane: function(){
        var perUnit = 155; // g / pkm
        return this.nbPeople * ( parseFloat(store.state.plane1) * this.g2t( perUnit ) );
      },
      cCar: function(){
        var perUnit = 150; // g / pkm
        return (parseFloat(store.state.car1) + parseFloat(store.state.car2)) * this.g2t(perUnit);
      },
      cTrain: function(){
        var perUnit = 0.05; // g / pkm
        return (this.nbPeople * ( parseFloat(store.state.train1) + parseFloat(store.state.train2))) * this.g2t( perUnit );
      },
      cBike: function(){
        var perUnit = 0; // g / pkm
        return (parseFloat(store.state.bike1) + parseFloat(store.state.bike2)) * perUnit;
      },
      cBus: function(){
        var perUnit = 38.23; // g / pkm
        return this.nbPeople * ( (parseFloat(store.state.bus1) + parseFloat(store.state.bus2)) * this.g2t( perUnit ) );
      },
      cCablecar: function(){
        var perUnit = 50; // g / pkm
        return this.nbPeople * ( parseFloat(store.state.cablecar) * this.g2t( perUnit ) );
      },
      cTotalTransport: function(){
        var n =  this.cPlane + this.cCar + this.cTrain + this.cBus + this.cBike + this.cCablecar;
        return this.round(n);
      },
      //
      cRestaurant: function() {
        var perUnit = 0.00452; // en t/repas, non végétarien
        return this.nbPeople * ( (store.state.restaurant) * perUnit );
      },
      cDejeuner: function() {
        var perUnit = 0.001; // en t/repas, non végétarien
        return this.nbPeople * ( (store.state.dejeuner) * perUnit );
      },
      cPiquenique : function() {
        var perUnit = 0.0008; // en t/repas
        return this.nbPeople * ( (store.state.piquenique) * perUnit );
      },
      cRobinet: function(){
        var perUnit = 0.00000017; // en t/litre
        return this.nbPeople * ( (store.state.robinet) * perUnit );
      },
      cBouteille: function(){
        var perUnit = 0.000196; // en t/litre
        return this.nbPeople * ( (store.state.bouteille) * perUnit );
      },
      cCafe: function(){
        var perUnit = 0.000114; // en t/tasse
        return this.nbPeople * ( (store.state.cafe) * perUnit );
      },
      cSoda: function(){
        var perUnit = 0.001090; // en t/litre
        return this.nbPeople * ( (store.state.soda) * perUnit );
      },
      cJusfruit: function(){
        var perUnit = 0.001530; // en t/litre
        return this.nbPeople * ( (store.state.jusfruit) * perUnit );
      },
      cAlcool: function(){
        var perUnit = 0.001430; // en t/litre
        return this.nbPeople * ( (store.state.alcool) * perUnit );
      },
      cTotalRepas: function(){
        var n = this.cRobinet + this.cBouteille + this.cCafe + this.cSoda + this.cJusfruit + this.cAlcool + this.cRestaurant + this.cDejeuner + this.cPiquenique;
        return this.round(n);
      },
      //
      cSki: function(){
        var perUnit = 0.00020; // en t/jour
        return this.nbPeople * ( (store.state.ski) * perUnit );
      },
      cRando: function(){
        var perUnit = 0; // en t/jour
        return this.nbPeople * ( (store.state.rando) * perUnit );
      },
      cBaignade: function(){
        var perUnit = 0.00012; // en t/jour
        return this.nbPeople * ( (store.state.baignade) * perUnit );
      },
      cMusee: function(){
        var perUnit = 0.00001; // en t/visite
        return this.nbPeople * ( (store.state.musee) * perUnit );
      },
      cTotalActivites: function(){
        var n = this.cSki + this.cRando + this.cBaignade + this.cMusee;
        return this.round(n);
      },
      //
      cHotel1: function(){
        var perUnit = 0.012; // en t/jour
        return this.nbPeople * ( (store.state.hotel1) * perUnit );
      },
      cHotel4: function(){
        var perUnit = 0.0245; // en t/jour
        return this.nbPeople * ( (store.state.hotel4) * perUnit );
      },
      cAuberge: function(){
        var perUnit = 0.008; // en t/jour
        return this.nbPeople * ( (store.state.auberge) * perUnit );
      },
      cBnb: function(){
        var perUnit = 0.008; // en t/jour
        return this.nbPeople * ( (store.state.bnb) * perUnit );
      },
      cCamping: function(){
        var perUnit = 0.004; // en t/jour
        return this.nbPeople * ( (store.state.camping) * perUnit );
      },
      cTotalLogement: function(){
        var n = this.cHotel1 + this.cHotel4 + this.cAuberge + this.cBnb + this.cCamping;
        return this.round(n);
      },
      graph: function(){
        // Nombre de tonnes / jour en moyenne pour la suisse
        // var mSuisse = this.moyenneSuisse;
        // pourcentage de la consommation du voyage par rapport à la moyenne Suisse
        var pUser = this.globalMoyenneSuisse;
        // Pourcentage Suisse à afficher dans le graph. 
        var pSuisse = 100;
        var more = false;
        if(pUser > 100) {
          pSuisse = 100 * 100 / pUser;
          pUser = 100;
          more = true;
        }

        return {user: pUser, suisse: pSuisse, more: more};
      },

      
      // Transports pour venir
      train1: () => store.state.train1,
      bus1: () => store.state.bus1,
      car1: () => store.state.car1,
      bike1: () => store.state.bike1,
      plane1: () => store.state.plane1,
      // Transports sur place
      train2: () => store.state.train2,
      bus2: () => store.state.bus2,
      car2: () => store.state.car2,
      bike2: () => store.state.bike2,
      cablecar: () => store.state.cablecar,
      // repas
      restaurant: () => store.state.restaurant,
      piquenique: () => store.state.piquenique,
      dejeuner: () => store.state.dejeuner,
      // Boissons
      robinet: () => store.state.robiner,
      bouteille: () => store.state.bouteille,
      cafe: () => store.state.cafe,
      soda: () => store.state.soda,
      jusfruit: () => store.state.jusfruit,
      alcool: () => store.state.alcool,
      // Activités
      ski: () => store.state.ski,
      rando: () => store.state.rando,
      baignade: () => store.state.baignade,
      musee: () => store.state.musee,
      //logement
      hotel1: () => store.state.hotel1,
      hotel4: () => store.state.hotel4,
      auberge: () => store.state.auberge,
      bnb: () => store.state.bnb,
      camping: () => store.state.camping,

      cNouilles: function(){
        // 1l d'eau pour 100g de nouilles
        // 10gCO2/litre d'eau
        //
        // 10 => 1
        // cPerPeople => ?

        // Divisé par 100 car il faut 10g par litre et 1l pour 100g. 1kg -> 100g --> a nouveau /10 
        return this.t2g(this.cPerPeople) / 100;
      },
      cArbres: function(){
        // 30kg de CO2 par arbre par an
        var kg = (this.t2g(this.cPerPeople) / 1000);
        return this.round(kg / 30);
      },
      cSmartphone: function(){
        // 0.7g de CO2 par recharge
        return parseInt(this.round(this.t2g(this.cPerPeople) / 0.7));
      },
    },
  }
</script>

<template>
    <div class="wrapper">
        <div class="row top">
          <HelloWorld :msg="$t('default.titre-bilan', {n1:cTotal, n2:cPerDay})"/>
          <form method="post" :action="getFormLink()" target="_blank">
              <input type="hidden" name="datas" :value="serializeDatas()">
              <input type="submit" class="compensate" :value="$t('default.compensez-vos-emissions')">
          </form>
        </div>
        <div class="row fingerprint">
          <div class="col-md-6 title">
            <h2>{{ $t('default.empreinte-par-personne') }} <span>{{ $t('default.tonne-co2', {n1:cPerPeople}) }}</span></h2>
          </div> 
          <div class="col-md-6 graph" id="graph" :class="graph.more ? 'more': ''">
            <div id="suisse" class="bar" v-bind:style="{ height: graph.suisse + '%' }">
              <div class="moyLine" v-html="$t('default.moyenne-suisse')">
              </div>
            </div>
            <div id="user" class="bar" v-bind:style="{ height: graph.user + '%' }">
              <div class="titleLine">
                {{ $t('default.votre-sejour') }}
              </div>
            </div>
          </div> 
        </div>
        <div class="row compare-ctn">
          <div class="col-md-12">
            <h2>{{ $t('default.titre-equivalent', {n1:cPerPeople}) }}</h2>
          </div>
          <div class="col-md-4 d-flex compare">
            <img src="result-noodles.svg"/>
            <p>{{ $t('default.calcul-nouilles', {n1:cNouilles}) }}</p>
          </div>
          <div class="col-md-4 d-flex compare">
            <img src="result-tree.svg"/>
            <p>{{ $t('default.calcul-arbres', {n1:cArbres}) }}</p>
          </div>
          <div class="col-md-4 d-flex compare">
            <img src="result-charging-phone.svg"/>
            <p>{{ $t('default.calcul-smartphone', {n1:cSmartphone}) }}</p>
          </div>
        </div>
        <div class="row">
          <div class="accordions">
            <div class="accordion">
              <div class="title">
                <div class="left">
                  <h3 v-html="$t('default.bilan-titre-transport', {n1: cTotalTransport})"></h3>
                </div>
                <div class="right">
                </div>                
              </div>
              <div class="accordion-content">
                <div class="spacer"></div>
                <div class="row">
                  <div class="col-12 text">Votre consommation de viande est un peu plus élevée que la moyenne. Carbon Fri vous propose de nouvelles expériences pour vous et vos papilles.</div>
                </div>
                <div class="row cards-container">
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                </div>
                <div class="spacer"></div>
              </div>
            </div>
            <div class="accordion"  >
              <div class="title">
                <div class="left">
                  <h3 v-html="$t('default.bilan-titre-repas', {n1: cTotalRepas})"></h3>
                </div>
                <div class="right">
                </div>                
              </div>
              <div class="accordion-content">
                <div class="spacer"></div>
                <div class="row">
                  <div class="col-12 text">Votre consommation de viande est un peu plus élevée que la moyenne. Carbon Fri vous propose de nouvelles expériences pour vous et vos papilles.</div>
                </div>
                <div class="row cards-container">
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                </div>
                <div class="spacer"></div>
              </div>
            </div>
            <div class="accordion">
              <div class="title">
                <div class="left">
                  <h3 v-html="$t('default.bilan-titre-activites', {n1: cTotalActivites})"></h3>
                </div>
                <div class="right">
                </div>                
              </div>
              <div class="accordion-content">
                <div class="spacer"></div>
                <div class="row">
                  <div class="col-12 text">Votre consommation de viande est un peu plus élevée que la moyenne. Carbon Fri vous propose de nouvelles expériences pour vous et vos papilles.</div>
                </div>
                <div class="row cards-container">
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                </div>
                <div class="spacer"></div>
              </div>
            </div>
            <div class="accordion">
              <div class="title">
                <div class="left">
                  <h3 v-html="$t('default.bilan-titre-logement', {n1: cTotalLogement})"></h3>
                </div>
                <div class="right">
                </div>                
              </div>
              <div class="accordion-content">
                <div class="spacer"></div>
                <div class="row">
                  <div class="col-12 text">Votre consommation de viande est un peu plus élevée que la moyenne. Carbon Fri vous propose de nouvelles expériences pour vous et vos papilles.</div>
                </div>
                <div class="row cards-container">
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                  <div class="col-md-4 col-12">
                    <a href="#" class="card">
                      <img src="https://via.placeholder.com/468x380">
                      <div class="card-content">
                        <p>Du pain zéro carbone</p>
                        <span class="more">{{ $t('default.plus-de-details') }}</span>
                      </div>
                    </a>
                  </div> 
                </div>
                <div class="spacer"></div>
              </div>
            </div>
          </div>
            
        </div>
        <div class="row">
          <section class="supportUs">
            <h4>{{ $t('default.soutenez-des-projets') }}</h4>
            <form method="post" :action="getFormLink()" target="_blank">
              <input type="hidden" name="datas" :value="serializeDatas()">
              <input type="submit" class="compensate" :value="$t('default.compensez-vos-emissions')">
            </form>
          </section>
          <!--
          <section class="weSupport">
            <h3>{{ $t('default.titre-projets-soutenus') }}</h3>
            <div class="row cards-container">
              <div class="col-md-4 col-12">
                <a href="#" class="card">
                  <img src="https://via.placeholder.com/468x380">
                  <div class="card-content">
                    <p>Du pain zéro carbone</p>
                    <span class="more">{{ $t('default.plus-de-details') }}</span>
                  </div>
                </a>
              </div>
              <div class="col-md-4 col-12">
                <a href="#" class="card">
                  <img src="https://via.placeholder.com/468x380">
                  <div class="card-content">
                    <p>Du pain zéro carbone</p>
                    <span class="more">{{ $t('default.plus-de-details') }}</span>
                  </div>
                </a>
              </div> 
            </div>  
          </section>
        -->
        </div>
    </div>
</template>

<style lang="less">
  * {
    font-family: 'Rubik' !important;
    font-weight: 300 !important;
  }
  button {
    float: initial;
    margin-top: 30px;
  }
  input[type=submit] {
    background-color: #20194C;
    color: white;
    border-radius: 2px;
    border: none;
    padding: 8px 20px;
    font-size: 16px;
    margin-left: 15px;
    float: initial;
    margin-top: 30px;
  }
  .top {
    padding-top: 100px;
    h2 {
      font-size: 48px;
    }
  }
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .cards-container {
    width: 100%;
    .card {
      border-radius: 4px;
      border: rgba(92, 127, 190, 0.2) solid 1px;
      height: 345px;
      display: flex;
      flex-direction: column;
      transition: all linear .2s;
      &:hover {
        text-decoration: none;
        transform: translateY(-5px);
        box-shadow: 0 10px 14px rgba(92, 127, 190, 0.15);
        border: #5c7fbe solid 3px;
      }
      .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        p {
          font-size: 16px;
          color: black;
        }
        span {
          font-size: 14px;
          color: #5C7FBE;
        }
        * {
          padding: 20px;
        }
      }
      img {
        height: 172px;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .fingerprint {
    .title {
      height: 400px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      text-align: left;
      font-size: 36px;
      line-height: 42px;
      span {
        display: block;
        color: #5C7FBE;
      }
    }
    #graph {
      height: 220px;
      margin-top: 90px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      .bar {
        width: 100px;
        height: 200px;
        background-color: red;
        position: relative;
      }
      &.more {
        #user {
          background: linear-gradient(0deg, #E10000 0%, #FF5858 76.04%);
        }
      }
      #user {
        background: linear-gradient(0deg, #5C7FBE 0%, #649BFF 100%);
        border-radius: 3px;
        margin-left: 50px;
      }
      #suisse {
        background: linear-gradient(0deg, #20194C 0%, #30266F 100%);
        border-radius: 3px;
      }
      .moyLine {
        width: 100px;
        position: absolute;
        top: -45px;
        display: flex;
        justify-content: flex-start;
        font-size: 9pt;
        z-index: 10;
        white-space: nowrap;
        text-align: left;
        &:before {
          content: "";
          position: absolute;
          bottom: -4px;
          border-bottom: 1px dashed #5C7FBE;
          width: 300px;
          left: -25px;
        };
      }
      .titleLine {
        width: 100%;
        text-align: center;
        position: absolute;
        top: -20px;
        font-size: 9pt;
      }
    }
  }
  .supportUs {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .weSupport {
    padding-bottom: 50px;
    h3 {
      border-bottom: rgba(92, 127, 190, 0.2) solid 1px;
      text-align: left;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
  }
  .compare-ctn {
    h2 {
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 50px;
    }
    .compare {
      padding: 0 7%;
      img {
        width: 130px;
        margin-bottom: 40px;
      }
    }
  }
  .accordions {
    margin-bottom: 50px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    .accordion {
      transition: all linear 3s;
      &:last-child {
        border-bottom: rgba(92, 127, 190, 0.2) solid 1px;  
      }
      padding: 20px 0;
      border-top: rgba(92, 127, 190, 0.2) solid 1px;
      display: flex;
      flex-direction: column;
      position: relative;
      .title {
        font-family: 'Rubik';
        font-weight: 300;
        display: flex;
        justify-content: space-between;
        .left, .right {
          display: flex;
          flex-direction: row;
          align-items: center;
          h3 {
            color: #20194C;
            font-size: 36px;
            font-weight: 300;
            span {
              color: #5C7FBE;
            }
          }
          .details {
            color: #20194C;
            padding: 5px;
            border-radius: 4px;
            border: rgba(92, 127, 190, 0.2) solid 1px;
          }
          small {
            border-radius: 4px;
            border: rgba(92, 127, 190, 0.2) solid 1px;
            padding: 2px 5px;
            margin-top: 14px;
            margin-left: 30px;
            color: #D7291E;
            &.good {
              color: green;
            }
          }
        }
      }
      &.active {
        .accordion-content {
          max-height: 600px;
        }
      }
      .accordion-content {
        padding-left: 20px;
        padding-right: 20px;
        transition: all ease-in-out .8s;
        max-height: 1px;
        overflow: hidden;
        flex-direction: column;
        .text {
          padding: 40px 0;
        }
        .spacer {
          width: 100%;
          height: 30px;
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 968px) {
      .hello {
        h2 {
          font-size:20pt;
          line-height:24pt;
        }
      }
      .top {
        padding-top: 20px;
      }
      .hello {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
      }
      .hello h2 {
        width: 90%;
        margin: 0 auto;
      }
      button.compensate {
        margin-top: 0;
        margin-bottom: 60px;
        margin-top: 10px;
      }
      .fingerprint {
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
          height: 100px;
          text-align: center;
        }
        #graph {
          margin-bottom: 100px;
        }
      }
      .compare {
        display: flex;
        align-items: center;
        p {
          font-size: 18px;
          text-align: left;
          padding-left: 20px;
          font-weight: 400;
        }
      }
      .accordions {
        padding: 0 15px;
        margin-top: 50px;
        .accordion {
          padding: 15px 0;
          .title {
            h3 {
              font-size: 18px !important;
            }
          }
        }
      }
      .supportUs {
        width: 90%;
        margin: 0 auto;
      }
      .weSupport {
        padding: 0 15px;
        .cards-container {
          margin: 0;
          .card {
            height: auto;
            flex-direction: row-reverse;
            margin-bottom: 20px;
            img {
              width: 40%;
              height: auto;
            }
            .card-content {
              width: 100%;
              align-self: center;
              padding-left: 20px;
              * {
                padding: 0;
                font-size: 18px;
                text-align: left;
              }
            }
          }
        }
      }
  }
</style>

