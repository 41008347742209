<template>
  <div class="wrapper">
    <transition name="scale" mode="out-in">
         <div v-if="step==1">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-transport-venir')"/>
            <MagicInput :cval="train1" modelValue="train1" :min="0" :unit="$t('default.km')" :name="$t('default.train')" src="transport-train.svg" :step="10"/>
            <MagicInput :cval="bus1" modelValue="bus1" :min="0" :unit="$t('default.km')" :name="$t('default.bus')" src="transport-bus.svg" :step="10"/>
            <MagicInput :cval="car1" modelValue="car1" :min="0" :unit="$t('default.km')" :name="$t('default.voiture')" src="transport-car.svg" :step="10"/>
            <MagicInput :cval="bike1" modelValue="bike1" :min="0" :unit="$t('default.km')" :name="$t('default.velo')" src="transport-bike.svg" :step="1"/>
            <MagicInput :cval="plane1" modelValue="plane1" :min="0" :unit="$t('default.km')" :name="$t('default.avion')" src="transport-plane.svg" :step="100"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
        <div v-else-if="step==2">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-transport-sur-place')"/>
            <MagicInput :cval="train2" modelValue="train2" :min="0" :unit="$t('default.km')" :name="$t('default.train')" src="transport-train.svg" :step="10"/>
            <MagicInput :cval="bus2" modelValue="bus2" :min="0" :unit="$t('default.km')" :name="$t('default.bus')" src="transport-bus.svg" :step="10"/>
            <MagicInput :cval="car2" modelValue="car2" :min="0" :unit="$t('default.km')" :name="$t('default.voiture')" src="transport-car.svg" :step="10"/>
            <MagicInput :cval="bike2" modelValue="bike2" :min="0" :unit="$t('default.km')" :name="$t('default.velo')" src="transport-bike.svg" :step="10"/>
            <MagicInput :cval="cablecar" modelValue="cablecar" :min="0" :unit="$t('default.km')" :name="$t('default.telecabine')" src="transport-cablecar.svg" :step="1"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="previousStep()">Précédent</button> 
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>

<style lang="less">
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .hello {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .hello h2 {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    .input-ctn {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import MagicInput from '@/components/MagicInput.vue'

  import store from '../store';

  export default {
    name: 'Home',
    data: function() {
      return {
        nbStep: 2,
      }
    },
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', false);
      this.$nextTick(function () {
        this.setAdvancement();
      })
    },
    methods: {
      nextStep: function () {
        var cStep = this.step;
        if(this.step < this.nbStep) {
          this.$router.replace({ name: 'transports', query: { step: parseInt(cStep)+1 }})
          this.setAdvancement();
        }else {
          this.setAdvancement(this.nbStep+1)
          this.$router.replace({ name: 'repas', query: { step: 1 }})
        }
      },
      previousStep: function () {
        var cStep = this.step;
        if(this.step > 0) {
          this.$router.replace({ name: 'transports', query: { step: parseInt(cStep)-1 }})
          this.setAdvancement();
        }
      },
      setAdvancement: function(step) {
        var cStep = step || this.step;
        var advancement = this.advancement;
        advancement.transports = cStep * 100 / this.nbStep;
        this.$store.commit('advancement', advancement);
      }
    },
    watch: {
      $route(to) {
        if(to.name == 'transports'){
          this.setAdvancement(to.query.step);
        }
      }
    },
    components: {
      HelloWorld,
      MagicInput
    },
    computed: {
      train1: () => store.state.train1,
      bus1: () => store.state.bus1,
      car1: () => store.state.car1,
      bike1: () => store.state.bike1,
      plane1: () => store.state.plane1,
      train2: () => store.state.train2,
      bus2: () => store.state.bus2,
      car2: () => store.state.car2,
      bike2: () => store.state.bike2,
      cablecar: () => store.state.cablecar,
      advancement: () => store.state.advancement,
      step() {
        // We will see what `params` is shortly
        return this.$route.query.step
      }
    },
  }
  </script>
