<template>
  <div class="wrapper">
    <transition name="scale" mode="out-in">
         <div v-if="step==1">
          <div class="row input-ctn">
            <HelloWorld :msg="$t('default.titre-activites')"/>
            <MagicInput :cval="ski" modelValue="ski" :min="0" :unit="$t('default.jours')" :name="$t('default.ski')" src="activity-ski.svg" :step="1"/>
            <MagicInput :cval="rando" modelValue="rando" :min="0" :unit="$t('default.jours')" :name="$t('default.randonnee')" src="activity-rando.svg" :step="1"/>
            <MagicInput :cval="baignade" modelValue="baignade" :min="0" :unit="$t('default.jours')" :name="$t('default.baignade')" src="activity-swimming.svg" :step="1"/>
            <MagicInput :cval="musee" modelValue="musee" :min="0" :unit="$t('default.visites')" :name="$t('default.musee')" src="activity-museum.svg" :step="1"/>
          </div>
          <hr>
          <div class="row">
            <div class="col controls">
              <button @click="nextStep()">Suivant</button> 
            </div>
          </div>
        </div>
      </transition>
    </div>
</template>

<style lang="less">
  .wrapper {
    background-color: #F6F7F8;
    width: 100%;
  }
  .hello {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .hello h2 {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1024px) {
    .input-ctn {
      display: flex;
      flex-wrap: wrap;
    }
  }
</style>

<script>
  // @ is an alias to /src
  import HelloWorld from '@/components/HelloWorld.vue'
  import MagicInput from '@/components/MagicInput.vue'

  import store from '../store';

  export default {
    name: 'activites',
    data: function() {
      return {
        nbStep: 1,
      }
    },
    mounted() {
      this.$store.commit('displayNav', true);
      this.$store.commit('displayHeader', false);
      this.$nextTick(function () {
        this.setAdvancement();
      })
    },
    methods: {
      nextStep: function () {
        var cStep = this.step;
        if(this.step < this.nbStep) {
          this.$router.replace({ name: 'activites', query: { step: parseInt(cStep)+1 }})
          this.setAdvancement();
        }else {
          this.setAdvancement(this.nbStep+1)
          this.$router.replace({ name: 'logement', query: { step: 1 }})
        }
      },
      previousStep: function () {
        var cStep = this.step;
        if(this.step > 0) {
          this.$router.replace({ name: 'activites', query: { step: parseInt(cStep)-1 }})
          this.setAdvancement();
        }
      },
      setAdvancement: function(step) {
        var cStep = step || this.step;
        var advancement = this.advancement;
        advancement.activites = cStep * 100 / this.nbStep;
        this.$store.commit('advancement', advancement);
      }
    },
    watch: {
      $route(to, from) {
        console.log(from, to);
        if(to.name == 'activites'){
          this.setAdvancement(to.query.step);
        }
      }
    },
    components: {
      HelloWorld,
      MagicInput
    },
    computed: {
      ski: () => store.state.ski,
      rando: () => store.state.rando,
      baignade: () => store.state.baignade,
      musee: () => store.state.musee,

      advancement: () => store.state.advancement,
      step() {
        // We will see what `params` is shortly
        return this.$route.query.step
      }
    },
  }
  </script>
